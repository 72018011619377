import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import './Search.css'

import Navbar from '../Navbar/Navbar';

function Search() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);
  const [toId,setToId] = useState(null);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [isSearchProduct,setIsSearchProduct] = useState(false);



  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    /*else{
        if( userId)
          window.location.href = '/mode-select';
    }*/

    const searchKeyWord = localStorage.getItem('searchKeyWord');
    if(searchKeyWord){
      setIsSearchProduct(true);
      setQuery(searchKeyWord);
      localStorage.removeItem('searchKeyWord')
    }



  },[])


  const handleSendMessage = async() => {
    // Implement your logic to send a message
    console.log(`Sending message "${message}" to seller with ID: ${toId}`);

    try {
      await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/sendMessage', {
        senderId: id,
        receiverId: toId,
        message: message,
        status: 'new'
      });
    } catch (error) {
      console.error('Error sending message', error);
    }



    setIsModalOpen(false);
    setMessage('');




  };


  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('title');
  const [productType, setProductType] = useState('product');

  const [products,setProducts] = useState([]);
  /*const PrintableProducts = useMemo(()=>{
    console.log("I am in");
    let pros = [];
    for(let i = 0 ; i < products.length;i++){
      pros.push(<div className = 'productContainer'> 
        <h3>Title : {products[i].title}</h3>
        <h4>Price : {products[i].price}</h4>
        <p>Description : {products[i].description}</p>
        <button id={products[i].productid}>Delete Product</button>

       </div>)
    }

    return <div className="productsContainer">{pros}</div>

  },[products]);*/

  const printProducts = () =>{
    let pros = [];
    for(let i = 0 ; i < products.length;i++){
      pros.push(<div className = 'searchProductContainer'> 
        <h3>Title : {products[i].title}</h3>
        <h4>Price : {products[i].price}</h4>
        <p>Description : {products[i].description}</p>
        <button className='searchButton' id={products[i].productid} key = {products[i].sellerid} onClick={()=>{
          setToId(products[i].sellerid);
          setIsModalOpen(true)
        }
        

        }>Send Message</button>

        <button className='searchButton' id={products[i].productid} key = {products[i].sellerid} onClick={()=>{
          const data = {
            requestorId: id,
            sellerId: products[i].sellerid,
            productId: products[i].productid
          };

          axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/finance', data)
          .then(response => {
            console.log('Response:', response.data);
            alert(response.data.message);

          })
          .catch(error => {
            console.error('Error:', error);
            alert("Your request could not get response ")
          });

          console.log(products[i]);

        }
        }>Request Financing</button>

       </div>)
    }

    return <div className="productsContainer">{pros}</div>
    

  }
  

  const handleRadioChange = (event) => {
    setSearchType(event.target.value);
  }
  const handleRadioChange2 = (event) => {
    setProductType(event.target.value);
  }

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  }

  const searchProductDescription = async (keyword) => {
    try {
      const response = await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/searchProductDescription', { keyword });
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.error('Error making the request:', error);
    }
  };

  const searchProductTitle = async (keyword) => {
    try {
      const response = await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/searchProductTitle', { keyword });
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.error('Error making the request:', error);
      throw error;
    }
  };

  const searchServiceTitle = async (keyword) => {
    try {
      const response = await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/searchServiceTitle', { keyword });
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.error('Error making the request:', error);
      throw error;
    }
  };

  const searchServiceDescription = async (keyword) => {
    try {
      const response = await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/searchServiceDescription', { keyword });
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.error('Error making the request:', error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if(searchType === 'title'){

      if(productType == 'product'){
        searchProductTitle(query)
        .then(data => {
          console.log('Response from server:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      else{
        searchServiceTitle(query)
        .then(data => {
          console.log('Response from server:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }



      




    }
    else{
      if(productType == 'product'){
        searchProductDescription(query)
        .then(data => {
          console.log('Response from server:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      else{
        searchServiceDescription(query)
        .then(data => {
          console.log('Response from server:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });

      }
      
    }


    
  }

 

  return (
    <div>
      <div className="Search">
        
          
            <div className='SearchArea'>
              <h2>Search Product / Service</h2>

              <div className='searchAreaRadioButtons'>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="productType" 
                    value="product"
                    checked={productType === 'product'} 
                    onChange={handleRadioChange2}
                  />
                  Product
                </label>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="productType" 
                    value="service"
                    checked={productType === 'service'} 
                    onChange={handleRadioChange2}
                  />
                  Service
                </label>
              </div>


              <div className="search-form" >
                <input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  placeholder="Search..."
                  className="search-input" // Add a class for styling
                />
                <button type="submit" className="search-button" onClick={handleFormSubmit}>Search</button>
              </div>
              <div className='searchAreaRadioButtons'>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="searchType" 
                    value="title"
                    checked={searchType === 'title'} 
                    onChange={handleRadioChange}
                  />
                  Title
                </label>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="searchType" 
                    value="content"
                    checked={searchType === 'content'} 
                    onChange={handleRadioChange}
                  />
                  Content
                </label>
              </div>

              <div className='SearchResults'>
                {printProducts()}
                {isModalOpen && (
                  <div className="modal">
                    <div className="modalContent">
                      <span className="close" onClick={() => setIsModalOpen(false)}>
                        &times;
                      </span>
                      <h2>Send a Message</h2>
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type your message..."
                        rows="4"
                      />
                      <button className='searchButton' onClick={handleSendMessage}>Send Message</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
        </div>
      </div>
  );
}

export default Search;
