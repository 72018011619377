import React, { useState } from 'react';
import Header from '../Components/Header/Header';
import Partners from '../Components/Partners/Partners';
import ModeSelector from '../Components/ModeSelector/ModeSelector';

function ModeSelectorPage() {


  return (
    <div className="HomePage">
     <Header/>
     <ModeSelector/>
     <Partners/>

    </div>
  );
}

export default ModeSelectorPage;
