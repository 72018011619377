import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Login from './pages/Login';
import Register from './pages/Register';
import ModeSelectorPage from './pages/ModeSelectorPage';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import TechnicalHolderPage from './pages/TechnicalHolderPage';
import ChatPage from './pages/ChatPage';
import AddProduct from './pages/Product/AddProduct';
import YourProducts from './pages/Product/YourProducts';
import AddService from './pages/Service/AddService';
import YourServices from './pages/Service/YourServices';
import Navbar from './Components/Navbar/Navbar';
import Header from './Components/Header/Header';



function App() {
  const [id,setId] = useState(null);

  useEffect(()=>{
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId)
        setId(userId);
  },[])

  return (
    <Router>
      <div>
        
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/mode-select" element={<ModeSelectorPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/TechnicalHolder" element={<TechnicalHolderPage />} />
          <Route path="/messages" element={<ChatPage />} />

          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/yourproducts" element={<YourProducts />} />
          <Route path="/addservice" element={<AddService />} />
          <Route path="/yourservices" element={<YourServices />} />




        </Routes>
        {/*id ? <Navbar/> : <Header/>*/}
      </div>
    </Router>

  );
}

export default App;
