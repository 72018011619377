import React, { useState, useEffect } from 'react';
import './ModeSelector.css'; // Import the CSS file for styling
import axios from 'axios';

function ModeSelector() {
    const [id,setId] = useState(null);

    useEffect(()=>{
      const userId = localStorage.getItem('userId');
      console.log(userId);
      if(userId){
        setId(userId);
      }
    },[])

    // Define the function to update userType
    async function updateUserType(id, userType) {
      try {
        const response = await axios.post(`https://madiba-a4ac6c5b352b.herokuapp.com/update-user-type/${id}`, { userType });

        if (response.status === 200) {
          console.log('User type updated successfully');
        } else {
          console.error('Failed to update user type:', response.data.error);
        }
      } catch (error) {
        console.error('An error occurred while updating user type:', error);
      }
    }

  return (


      <div className='flexBox'>
        <h1>Please Select Usage Type</h1>
        <div className="ModeSelector">

        <div className='ModeSelector-Buttons'>
            <button onClick={()=>{
              updateUserType(id, "1");
              localStorage.setItem('userType',"1");
              window.location.href = '/';

            }}>Customer Mode</button>

            <button onClick={()=>{
              updateUserType(id, "2");
              localStorage.setItem('userType',"2");
              window.location.href = '/';

            }}>Technical Stack Holder Mode</button>

        </div>

        


        </div>

      </div>


  );
}

export default ModeSelector;


/*

<div className='ModeSelector-images'>
            <img src='https://media.licdn.com/dms/image/C4D03AQG0rB9kv42uRw/profile-displayphoto-shrink_800_800/0/1619115006136?e=1700092800&v=beta&t=jWjH9GN9DOpymOtp3mxjpsGSJ1EsoISeZ52JWnzxFvc'></img>

        </div>

        
*/