import React, { useState } from 'react';

import './Partners.css'
import Compass from './Compass.png';
import Lexus from './Lexus.png';

function Header() {


  return (
    <div className="Partners">
        <div className='BackgroundOverlay'>
        <h1>Partners</h1>

        <div className='Partner-Items'>
            <div className='Partner-Item item1' onClick={() => window.open("https://www.helsinki.fi/en/networks/helsinki-incubators/pre-incubators/compass-deep-tech-ai-sustainability", "_blank")}>   
                <p>COMPASS</p>
            </div>

            <div className='Partner-Item item2' onClick={() => window.open("https://www.helsinki.fi/en/networks/helsinki-incubators/incubators/nexus-deep-tech-ai-sustainability", "_blank")}>
                <p>NEXUS</p>
            </div>

            
        </div>

        </div>

        

        
    </div>
  );
}

export default Header;
