import React, { useState } from 'react';
import Header from '../Components/Header/Header';
import Partners from '../Components/Partners/Partners';
import ChatBoard from '../Components/Chat/ChatBoard';
function ChatPage() {


  return (
    <div className="HomePage">
     <Header/>
     <ChatBoard/>
     <Partners/>

    </div>
  );
}

export default ChatPage;
