import React, { useEffect, useState } from 'react';

import './Header.css'
import './SearchBar.css'; // Import the CSS file
import logo from '../../Assets/logo.png';
import Navbar from '../Navbar/Navbar';

function Header() {
  const [id,setId] = useState(null);
  const [userType,setUserType] = useState(null);
  const [query, setQuery] = useState('');

  const [menuOpen,setMenuOpen] = useState(false);
  const handleInputChange = (event) => {
    setQuery(event.target.value);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("searchKeyWord",query);
    window.location.href = '/Search';
    
  }

  useEffect(()=>{
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }

    const type = localStorage.getItem('userType');
    console.log(type);
    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    else{
      //if(window.location.href != '/mode-select')
        //window.location.href = '/mode-select';
    }




  },[])

  return (
    <div className='centerHeader'>
      <div className="Header">
              <div className='Header-Left'>
                  <a href='/'>
                    <img src={logo}></img>
                  </a>
                  <a href='/'>
                  <h1>Madiba</h1>
                  </a>
                  {/*!id?
                    <div>
            
                    </div>
                    
                    :
                    <>
                      {userType == '1' ? 
                      <div>
                        <button onClick={() => window.location.href = '/search'}>Search Product/Service</button>
                      </div> 
                      : 
                      <div>
                        <button onClick={() => window.location.href = '/addproduct'}>Add Product</button>
                        <button onClick={() => window.location.href = '/yourproducts'}>Your Products</button>

                        <button onClick={() => window.location.href = '/addservice'}>Add Service</button>
                        <button onClick={() => window.location.href = '/yourservices'}>Your Services</button>
                      </div>}
                    </>
                  */}
                  
              </div>

              <div className='Header-Right'>

                {/*
                  userType == '1'?
                  <form className="search-form" onSubmit={handleFormSubmit}>
                    <input
                      type="text"
                      value={query}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      className="search-input" // Add a class for styling
                    />
                    <button type="submit" className="search-button">Search</button>
                  </form>
                  :
                  <></>*/
                }

                {!id?
                  <div>
                    <button onClick={() => window.location.href = '/login'}>Log in</button>
                    <button onClick={() => window.location.href = '/register'}>Sign Up</button>
                  </div>
                :
                  <div>
                    {/*<button onClick={() => window.location.href = '/messages'}>Messages</button>
                    <button onClick={() => window.location.href = '/profile'}>Profile</button>*/}
                    <button onClick={() => {setMenuOpen(!menuOpen)}}>Menu</button>
                  </div>
                  
                }
              
              

              </div>
          </div>
          {menuOpen && <Navbar/>}
    </div>
    
  );
}

export default Header;
