import React, { useState } from 'react';
import Header from '../../Components/Header/Header';
import Partners from '../../Components/Partners/Partners';
import YourServicesComponent from '../../Components/Service/YourServicesComponent';

function YourServices() {


  return (
    <div className="HomePage">
     <Header/>
     <YourServicesComponent/>
     <Partners/>

    </div>
  );
}

export default YourServices;
