import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './Services.css'

function AddServiceComponent() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);
  const [services, setServices] = useState({});

  const [formData, setFormData] = useState({
    title: '',
    price: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    const data = {
      SellerId: id,         // Replace with actual values
      Price: Number(formData.price),          // Replace with actual values
      Description: formData.description,  // Replace with actual values
      Title: formData.title // Replace with actual values
    };
    
    axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/addService', data)
      .then((response) => {
        console.log('Response:', response.data);
        alert("Successfully added!");
        setFormData({
          title: '',
          price: '',
          description: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("We got error, while Adding");

      });

  };


  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    /*else{
        if( userId)
          window.location.href = '/mode-select';
    }*/

    axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getAllServicesForSeller/${userId}`)
      .then((response) => {
        console.log('Response:', response.data);
        setServices(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });



  },[])

  

  const printServices = () =>{
    let pros = [];
    for(let i = 0 ; i < services.length;i++){
      pros.push(<div className = 'serviceContainer'> 
        <h3>Title : {services[i].title}</h3>
        <h4>Price : {services[i].price}</h4>
        <p>Description : {services[i].description}</p>
        <button id={services[i].serviceid}>Delete Service</button>

       </div>)
    }

    return <div className="servicesContainer">{pros}</div>
    

  }


  return (
    <div>
      <div className="TechnicalHolderPage">
        <div className='TechnicalHolderPage-Right'>
            <div className="add-service-container">
            <h2>Add Service</h2>
            <form onSubmit={handleSubmit} className="add-service-form">
                <label htmlFor="title">Title:</label>
                <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                />
        
                <label htmlFor="price">Price:</label>
                <input
                type="number"
                id="price"
                name="price"
                min="0"
                step="0.01"
                value={formData.price}
                onChange={handleChange}
                required
                />
        
                <label htmlFor="description">Description:</label>
                <textarea
                id="description"
                name="description"
                rows="4"
                value={formData.description}
                onChange={handleChange}
                required
                ></textarea>
        
                <button type="submit">Add Service</button>
            </form>
            </div>
        </div>
      </div>
    </div>
  );
}

export default AddServiceComponent;
