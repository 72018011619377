import React, { useState } from 'react';
import Header from '../Components/Header/Header';
import DashBoard from '../Components/Dashboard/DashBoard';
import './HomePage.css';
import Partners from '../Components/Partners/Partners';

function HomePage() {


  return (
    <div className="HomePage">
     <Header/>
     <DashBoard/>
     <Partners/>

    </div>
  );
}

export default HomePage;
