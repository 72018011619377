import React, { useState } from 'react';
import Header from '../../Components/Header/Header';
import Partners from '../../Components/Partners/Partners';
import AddServiceComponent from '../../Components/Service/AddServiceComponent';
function AddService() {


  return (
    <div className="HomePage">
     <Header/>
     <AddServiceComponent/>
     <Partners/>

    </div>
  );
}

export default AddService;
