import React, { useState } from 'react';
import Header from '../Components/Header/Header';
import Profile from '../Components/Profile/Profile';
import Partners from '../Components/Partners/Partners';

function ProfilePage() {


  return (
    <div className="">
     <Header/>
     <Profile/>
     <Partners/>

    </div>
  );
}

export default ProfilePage;
