import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './ChatBoard.css'; // Assuming you have a CSS file named ChatBoard.css

const ChatBoard = () => {
  const [communicatedPeople, setCommunicatedPeople] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const id = localStorage.getItem('userId');
    console.log(id);
    if (id) {
      setUserId(id);
    }

    const fetchCommunicatedPeople = async () => {
      try {
        const response = await axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getCommunicatedPeople?userId=${userId}`);
        setCommunicatedPeople(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching communicated people', error);
      }
    };

    if (userId) {
      fetchCommunicatedPeople();
    }
  }, [userId]);

  const fetchConversation = async () => {
    try {
      const response = await axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getConversation?senderId=${userId}&receiverId=${selectedPerson}`);
      setMessages(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching conversation', error);
    }
  };

  useEffect(() => {
    if (selectedPerson !== null) {
      fetchConversation();
    }
  }, [userId, selectedPerson]);

  const handlePersonClick = (personId) => {
    setSelectedPerson(personId);
    console.log(personId);
  };

  const handleSendMessage = async () => {
    try {
      await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/sendMessage', {
        senderId: userId,
        receiverId: selectedPerson,
        message: newMessage,
        status: 'new'
      });
      setNewMessage('');
      await fetchConversation();
    } catch (error) {
      console.error('Error sending message', error);
    }
  };

  return (
    <div className="ChatBoard">
      <ul className="CommunicatedPeopleList">
        {communicatedPeople.map(personId => (
          <li key={personId} onClick={() => handlePersonClick(personId)}>
            Person {personId}
          </li>
        ))}
      </ul>

      {selectedPerson !== null && (
        <div className="SelectedConversation">
          <h2>Conversation with Person {selectedPerson}</h2>
          <ul>
            {messages.map((message, index) => (
              <li
                key={index}
                className={message.senderid == userId ? 'message right' : 'message left'}
              >
                {message.message}
              </li>
            ))}
          </ul>

          <input
            className="MessageInput"
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button className="SendButton" onClick={handleSendMessage}>Send</button>
        </div>
      )}
    </div>
  );
};

export default ChatBoard;
