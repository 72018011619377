import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import img1 from './images/img1.jpg';
import img2 from './images/img2.jpg';
import img3 from './images/img3.jpg';
import img4 from './images/img4.jpg';
import img5 from './images/img5.jpg';
import img6 from './images/img6.jpg';
import img7 from './images/img7.jpg';

import './DashBoard.css'

function DashBoard() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const images = [
      img1,img2,img3,img4,img5,img6,img7
  ];

  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);

    }
    else{
        if( userId)
          window.location.href = '/mode-select';
    }
  },[])


  return (
    
    <div className="carousel-container">
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                  <div className="image-container">
                    <img src={image} alt={`Image ${index}`} />
                    <div className="image-text">#1 Integrated sustainability platform in the world.</div>
                  </div>
                </div>
            ))}
        </Slider>
    </div>
        
        
   
  );
}


/*
<div className="DashBoard">
<div className='DashBoard-text'>
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut pharetra libero, a finibus arcu. Vestibulum vitae metus vel neque commodo rhoncus. Nulla facilisi. Integer nec diam ut quam tincidunt interdum. Sed suscipit justo in massa luctus cursus. Nullam ut feugiat odio. Vestibulum feugiat eros sit amet accumsan condimentum. Vivamus at metus ac turpis euismod volutpat. Integer vitae justo et libero auctor tincidunt. Aenean non mauris vel tellus efficitur congue vel et urna. Duis vulputate massa quis venenatis semper."

This is a placeholder text often used in the printing and typesetting industry. It doesn't have a specific meaning as it's meant to simulate the appearance of natural language.
"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut pharetra libero, a finibus arcu. Vestibulum vitae metus vel neque commodo rhoncus. Nulla facilisi. Integer nec diam ut quam tincidunt interdum. Sed suscipit justo in massa luctus cursus. Nullam ut feugiat odio. Vestibulum feugiat eros sit amet accumsan condimentum. Vivamus at metus ac turpis euismod volutpat. Integer vitae justo et libero auctor tincidunt. Aenean non mauris vel tellus efficitur congue vel et urna. Duis vulputate massa quis venenatis semper."

        </div>
<div className='DashBoard-image'>
            <img src='https://media.licdn.com/dms/image/C4D03AQG0rB9kv42uRw/profile-displayphoto-shrink_800_800/0/1619115006136?e=1700092800&v=beta&t=jWjH9GN9DOpymOtp3mxjpsGSJ1EsoISeZ52JWnzxFvc'></img>
        </div>
 </div>
*/
export default DashBoard;
