import React, { useState, useEffect } from 'react';
import Header from '../Components/Header/Header';
import Partners from '../Components/Partners/Partners';
import Search from '../Components/Search/Search';
import Navbar from '../Components/Navbar/Navbar';
function SearchPage() {
  const [id,setId] = useState(null);

  useEffect(()=>{
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId)
        setId(userId);
  },[])



  return (
    <div className="HomePage">
     <Header/>
     <Search/>
     <Partners/>
    

    </div>
  );
}

export default SearchPage;
