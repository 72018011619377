import React, { useState } from 'react';
import Header from '../../Components/Header/Header';
import Partners from '../../Components/Partners/Partners';
import AddProductComponent from '../../Components/Product/AddProductComponent';
function AddProduct() {


  return (
    <div className="HomePage">
     <Header/>
     <AddProductComponent/>
     <Partners/>

    </div>
  );
}

export default AddProduct;
