import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './Product.css'

function TechnicalHolderPage() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);
  const [products, setProducts] = useState({});


  


  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    /*else{
        if( userId)
          window.location.href = '/mode-select';
    }*/

    axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getAllProductsForSeller/${userId}`)
      .then((response) => {
        console.log('Response:', response.data);
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });



  },[])

  

  const printProducts = () =>{
    let pros = [];
    for(let i = 0 ; i < products.length;i++){
      pros.push(<div className = 'productContainer' key={products[i].productid}> 
        <h3>Title : {products[i].title}</h3>
        <h4>Price : {products[i].price}</h4>
        <p>Description : {products[i].description}</p>
        <button id={products[i].productid}>Delete Product</button>
        

       </div>)
    }

    return <div className="productsContainer">{pros}</div>
    

  }


  return (
      <div className="TechnicalHolderPage">
        

          <h2>Your Products</h2>

          {printProducts()}
        

      </div>
  );
}

export default TechnicalHolderPage;
