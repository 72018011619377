import React, { useState } from 'react';
import Header from '../../Components/Header/Header';
import Partners from '../../Components/Partners/Partners';
import YourProductsComponent from '../../Components/Product/YourProductsComponent';

function YourProducts() {


  return (
    <div className="HomePage">
     <Header/>
     <YourProductsComponent/>
     <Partners/>

    </div>
  );
}

export default YourProducts;
