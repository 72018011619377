import React, { useState } from 'react';
import './Form.css'; // Import the CSS file for styling
import axios from 'axios';

function RegisterComp() {
  const [formData, setFormData] = useState({
    name: '',
    password:'',
    email: '',
    address: '',  // Added Address field
    tin: '',      // Added TIN field
    phoneNumber: '' // Added Phone Number field
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your registration logic here (e.g., sending data to a server)
    console.log('Form Data:', formData);

     // Assuming you have a state variable 'password' to store the password value
  const { name, email, address, tin, phoneNumber, password } = formData;

  axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/register', {
    name,
    email,
    address,
    tin,
    phoneNumber,
    password
  })
    .then(response => {
      console.log('Registration successful:', response.data);
      localStorage.setItem('userId',response.data.id);
      window.location.href = '/mode-select';
    })
    .catch(error => {
      console.error('Error registering user:', error);
      alert("Error!!");
    });

  };

  return (


      <div className="FormContainer">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="FormField">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="name">Password</label>
            <input
              type="text"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="tin">TIN</label>
            <input
              type="text"
              id="tin"
              name="tin"
              value={formData.tin}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <button className='SubmitButton' type="submit">Register</button>
        </form>
      </div>


  );
}

export default RegisterComp;
