import React from 'react';
import './Navbar.css'; 
import logo from '../../Assets/logo.png';
import { useState, useEffect } from 'react';

import CloseIcon from '../../Assets/close.png';

 function Navbar() {

    const [id,setId] = useState(null);
    const [userType,setUserType] = useState(null);
    const [query, setQuery] = useState('');

    

    const handleInputChange = (event) => {
        setQuery(event.target.value);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem("searchKeyWord",query);
        window.location.href = '/Search';
        
    }

    useEffect(()=>{
        const userId = localStorage.getItem('userId');
        console.log(userId);
        if(userId){
            setId(userId);
        }

        const type = localStorage.getItem('userType');
        console.log(type);
        if(type && type != "undefined"){
        console.log("type",type);
        setUserType(type);
        }

    },[])




  
    return (
            <div className="navbarB">

            <div className="closeIcon" onClick={()=>{window.location.reload();}}>
                <img src={CloseIcon} alt="Close Icon" />
            </div>
                <div className="navbarItems">
                    <div className='headerContent'>
                        <a href="/">
                            <img src={logo}></img>
                        </a>

                        <a href="/">
                            <h1>Madiba</h1>
                        </a>
                    </div>
                    

                    {!id ? 
                        <div>

                        </div>
                        : 
                        <div  className="userButtons">
                            {userType == "1" ? (
                                <div className="searchArea">
                                    <input
                                        type="text"
                                        value={query}
                                        onChange={handleInputChange}
                                        placeholder="Search..."
                                        className="search-input" // Add a class for styling
                                    />
                                    <button onClick={handleFormSubmit}>
                                        Search
                                    </button>
                                </div>
                            ) : (
                                <div >
                                    <button onClick={() => (window.location.href = "/addproduct")}>
                                        Add Product
                                    </button>
                                    <button onClick={() => (window.location.href = "/yourproducts")}>
                                        Your Products
                                    </button>

                                    <button onClick={() => (window.location.href = "/addservice")}>
                                        Add Service
                                    </button>
                                    <button onClick={() => (window.location.href = "/yourservices")}>
                                        Your Services
                                    </button>
                                </div>
                            )}
                        </div>
                    }


                    <div className='MessagesAndProfile'>
                        <button onClick={() => (window.location.href = "/messages")}>
                            Messages
                        </button>
                        <button onClick={() => (window.location.href = "/profile")}>
                            Profile
                        </button>
                        <button onClick={()=>{
                            localStorage.removeItem("userType")
                            localStorage.removeItem("userId")
            
                            window.location.href = '/'
                        }}>
                            Logout
                        </button>
                    </div>
                    
                </div>
                
            </div>
    

    );
  
}

export default Navbar;
