import React, { useState } from 'react';
import './Form.css'; // Import the CSS file for styling
import axios from 'axios';
function LoginComp() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Define the login function
async function loginUser(email, password) {
  try {
    const response = await axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/login ', { email, password });

    if (response.status === 200) {
      // Login successful
      const { id, usertype } = response.data;
      console.log('Login successful. User ID:', id);
      console.log('User Type:', usertype);
      localStorage.setItem('userId',id);
      localStorage.setItem('userType',usertype);

      if(usertype){
        window.location.href = '/';
      }
      else{
        window.location.href = '/mode-select';
      }

      

      // You can save the user's information in your application state or context here.
    } else {
      // Handle other response statuses or errors here.
      console.error('Login failed:', response.data.error);
    }
  } catch (error) {
    // Handle network errors or other exceptions here.
    console.error('An error occurred while logging in:', error);
  }
}

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here (e.g., sending data to a server)
    console.log('Form Data:', formData);
    loginUser(formData.email,formData.password);

  };

  return (

      <div className="FormContainer">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="FormField">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="FormField">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className='SubmitButton' type="submit">Login</button>
        </form>
      </div>

    
  );
}

export default LoginComp;
