import React, { useEffect, useState } from 'react';

import './Profile.css'

function Profile() {
  const [id,setId] = useState(null);
  const [userType,setUserType] = useState(null);


  useEffect(()=>{
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }

    const type = localStorage.getItem('userType');
    console.log(type);
    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }





  },[])

  return (
    <div className="Profile">
        <div className='Profile-Left'>
            <button onClick={() => {
                window.location.href = '/mode-select'
            }}>Update Usage Type</button>


            <button onClick={() => {
                localStorage.removeItem("userType")
                localStorage.removeItem("userId")

                window.location.href = '/'
            }}>Log out</button>


            
        </div>

        <div className='Profile-Right'>
       
        

        </div>
    </div>
  );
}

export default Profile;
