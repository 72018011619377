import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './Services.css'

function TechnicalHolderPage() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);
  const [services, setServices] = useState({});


  


  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    /*else{
        if( userId)
          window.location.href = '/mode-select';
    }*/

    axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getAllServicesForSeller/${userId}`)
      .then((response) => {
        console.log('Response:', response.data);
        setServices(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });



  },[])

  

  const printServices = () =>{
    let pros = [];
    for(let i = 0 ; i < services.length;i++){
      pros.push(<div className = 'serviceContainer' key={services[i].serviceid}> 
        <h3>Title : {services[i].title}</h3>
        <h4>Price : {services[i].price}</h4>
        <p>Description : {services[i].description}</p>
        <button id={services[i].serviceid}>Delete Service</button>
        

       </div>)
    }

    return <div className="servicesContainer">{pros}</div>
    

  }


  return (
      <div className="TechnicalHolderPage">
        

          <h2>Your Services</h2>

          {printServices()}

      </div>
  );
}

export default TechnicalHolderPage;
