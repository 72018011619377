import React, { useState } from 'react';
import Header from '../Components/Header/Header';
import Partners from '../Components/Partners/Partners';
import TechnicalHolderPageComp from '../Components/TechnicalHolderPage/TechnicalHolderPage';
function TechnicalHolderPage() {


  return (
    <div className="HomePage">
     <Header/>
     <TechnicalHolderPageComp/>
     <Partners/>

    </div>
  );
}

export default TechnicalHolderPage;
