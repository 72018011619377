import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './TechnicalHolderPage.css'

function TechnicalHolderPage() {
  const [userType,setUserType] = useState(null);
  const [id,setId] = useState(null);
  const [isShowProducts,setIsShowProducts] = useState(false);
  const [isAddProducts,setIsAddProducts] = useState(false);
  const [products, setProducts] = useState({});

  const [formData, setFormData] = useState({
    title: '',
    price: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    const data = {
      SellerId: id,         // Replace with actual values
      Price: Number(formData.price),          // Replace with actual values
      Description: formData.description,  // Replace with actual values
      Title: formData.title // Replace with actual values
    };
    
    axios.post('https://madiba-a4ac6c5b352b.herokuapp.com/addProduct', data)
      .then((response) => {
        console.log('Response:', response.data);
        alert("Successfully added!");
        setFormData({
          title: '',
          price: '',
          description: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("We got error, while Adding");

      });

  };


  useEffect(()=>{
    const type = localStorage.getItem('userType');
    console.log(type);
    const userId = localStorage.getItem('userId');
    console.log(userId);
    if(userId){
      setId(userId);
    }


    if(type && type != "undefined"){
      console.log("type",type);
      setUserType(type);
    }
    /*else{
        if( userId)
          window.location.href = '/mode-select';
    }*/

    axios.get(`https://madiba-a4ac6c5b352b.herokuapp.com/getAllProductsForSeller/${userId}`)
      .then((response) => {
        console.log('Response:', response.data);
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });



  },[])

  

  const printProducts = () =>{
    let pros = [];
    for(let i = 0 ; i < products.length;i++){
      pros.push(<div className = 'productContainer'> 
        <h3>Title : {products[i].title}</h3>
        <h4>Price : {products[i].price}</h4>
        <p>Description : {products[i].description}</p>
        <button id={products[i].productid}>Delete Product</button>

       </div>)
    }

    return <div className="productsContainer">{pros}</div>
    

  }


  return (
    <div>
      TechnicalHolder Page
      <div className="TechnicalHolderPage">
        
        <div className='TechnicalHolderPage-Left'>
        <button onClick={()=>{ setIsShowProducts(true); setIsAddProducts(false); }}> Your Products </button>
        <button onClick={()=>{ setIsShowProducts(false); setIsAddProducts(true); }}> Add Product </button>

        </div>

        <div className='TechnicalHolderPage-Right'>
        {isAddProducts?
        <div className="add-product-container">
          <h2>Add Product</h2>
          <form onSubmit={handleSubmit} className="add-product-form">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
    
            <label htmlFor="price">Price:</label>
            <input
              type="number"
              id="price"
              name="price"
              min="0"
              step="0.01"
              value={formData.price}
              onChange={handleChange}
              required
            />
    
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              rows="4"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
    
            <button type="submit">Add Product</button>
          </form>
        </div>
        :
        <></>
        }

        {isShowProducts?
        <div>
          <h2>Your Products</h2>

          {printProducts()}
        </div>
        
        :
        <></>
        }


        </div>
      </div>
    </div>
  );
}

export default TechnicalHolderPage;
